import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`

  :root {
    --bannerTitle: 34px;
    --borderSpacing: 10px;
    --borderSpacing-small: 10px;
    --menuWidth: 100vw;
    --blockquote: 20px;
    --background: #3A0D23;
    --light-colour: #fff;
    --dark-colour: #000;
    --containerWidth: 1024px;
    --primary: var(--background);//#ffc400;
    --font-family: 'Graduate', sans-serif;
    --focus-colour: #ffc400;
    --primary-rgb: 58, 13, 35;
    --bodyColor: #ffffff;
    --inActive: rgba(255,255,255, 0.5);
    --letterSpacing: -0.075rem;
    --boxShadow: 0px 15px 22px 3px rgba(0, 0, 0, 0.55);
    --textShadow: 0px 0px 5px rgba(0, 0, 0, 1);
    --p: 14px;
    --h2: 20px;
    --h3: 19px;
    --h4: 18px;
    --h5: 17px;
    --h6: 16px;
    --gap: 40px;
    --sectionMargin: 20px;
    --screen-xs: 375px;
    --screen-s: 414px;
    --screen-m: 768px;
    --screen-l: 1024px;
    --screen-xl: 1200px;

    @media (min-width: 375px) {
      --bannerTitle: 36px;
      --h2: 22px;
      --h3: 21px;
      --h4: 20px;
      --h5: 19px;
      --h6: 18px;
      --sectionMargin: 40px;
    }

    @media (min-width: 414px) {
      --bannerTitle: 40px;
      --h2: 27px;
      --h3: 25px;
      --h4: 23px;
      --h5: 21px;
      --h6: 20px;
    }

    @media (min-width: 768px) {
      --bannerTitle: 46px;
      --blockquote: 26px;
      --p: 15px;
      --h2: 30px;
      --h3: 28px;
      --h4: 26px;
      --h5: 24px;
      --h6: 22px;
      --sectionMargin: 80px;
    }

    @media (min-width: 1024px) {
      --borderSpacing: 75px;
      --p: 16px;
      --h2: 36px;
      --h3: 32px;
      --h4: 29px;
      --h5: 27px;
      --h6: 25px;
      --sectionMargin: 100px;
    }

    @media (min-width: 1200px) {
      --p: 17px;
    }
  }

  * {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }


  body {
    font-family: var(--font-family);
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--background);
    color: #fff;
    overflow-x: hidden;
    font-size: var(--p);
  }

  h1,
  h2 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    line-height: 1.25em;
    font-weight: 700;
    letter-spacing: var(--letterSpacing);
    text-align: center;
    text-transform: uppercase;
    
    @media (min-width: 1200px) {
      margin-bottom: 1.5rem;
    }
  }

  h3,
  h4,
  h5,
  h6 {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: var(--bannerTitle);
  }

  h2 {
    font-size: var(--h2);
  }

  h3 {
    font-size: var(--h3);
  }

  h4 {
    font-size: var(--h4);
  }

  h5 {
    font-size: var(--h5);
  }

  h6 {
    font-size: var(--h6);
  }

  p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: var(--bodyColor);
  }

  b,
  strong {
    font-weight: 700;
  }

  i {
    font-style: italic;
  }
  
  ul, ol {
    margin: 0;
    padding: 0;
  }

  .underline {
    text-decoration: underline;
    text-underline-position: under;
    text-decoration-color: rgba(175, 194, 203, 0.25);
    text-decoration-thickness: 0.125rem;
  }

  .visually-hidden {
    position: absolute;

    width: 1px;
    height: 1px;
    // If margin is set to a negative value it can cause text to be announced in
    // the wrong order in VoiceOver for OSX
    margin: 0;
    padding: 0;

    overflow: hidden;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);

    border: 0;

    // For long content, line feeds are not interpreted as spaces and small width
    // causes content to wrap 1 word per line:
    // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
    white-space: nowrap;
  }

  hr {
    border: none;
    height: 2px;
    background-color: #333;
  }

  a {
    color: var(--primary);
    transition: color 0.3s ease;
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: var(--containerWidth);

    &__tight {
      max-width: var(--containerWidth);
      text-align: center;
    }

    &__scroll {
      overflow-x: scroll;
      display: flex;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        width: 14px;
        height: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 0;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: var(--primary);
      }

      &::-webkit-scrollbar-track {
        background: #000;
        border-radius: 0;
      }

      @media (min-width: 1200px) {
        overflow-x: visible;
      }
    }
  }
  
  .section {
    margin-top: var(--sectionMargin);
    margin-bottom: var(--sectionMargin);
    padding: 0 var(--borderSpacing);
    
    &--inverted {
      background: var(--dark-colour);
    }

    &.section__padding {
      padding-top: var(--sectionMargin);
      padding-bottom: var(--sectionMargin);
    }
  }
`
