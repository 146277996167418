import styled from "styled-components"

export const FooterStyles = styled.footer`
  &.section {
    margin: var(--borderSpacing--small) 0;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: var(--gap);

      gap: var(--gap);

      @media (min-width: 768px) {
        //align-items: center;
        justify-content: space-between;
        flex-direction: row;
      }

      @media (min-width: 1200px) {
        gap: calc(var(--gap) * 2);
      }
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        a {
          transition: color 0.3s ease;
        }
      }
    }
  }
`

export const FooterMenuStyles = styled.div`
  @media (min-width: 768px) {
    flex-grow: 1;
    width: 33.333%;
  }

  h2 {
    margin-top: 0;
    display: inline-block;
    border-bottom: 2px solid #333;

    a {
      color: #fff;
      text-decoration: none;
    }
  }
  ul {
    margin-bottom: var(--gap);

    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
    }
  }

  li {
    margin-top: 5px;
    margin-bottom: 5px;

    a {
      color: var(--bodyColor);
      text-decoration: none;
      text-transform: capitalize;
      font-weight: 700;

      span {
        color: var(--primary);
      }
    }

    &:hover,
    &:focus {
      a {
        color: var(--primary);
      }
    }
  }

  &.social__menu {

    ul {
      display: flex;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: flex-end;
      }

      li {
        font-size: var(--h6);
        margin-right: var(--borderSpacing-small);

        @media (min-width: 768px) {
          margin-left: 5px;
          margin-right: 5px;
        }

        a {
          color: var(--light-colour);
          font-size: 2.2rem;
        }

        &:hover,
        &:focus {
          a {
            color: #fff;
          }
        }
      }
    }
  }
`
