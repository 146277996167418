import * as React from "react"
import { FooterStyles, FooterMenuStyles } from "./FooterStyles"
import { UseSiteMetadata } from "../../hooks/useSiteMetadata"
import {
  FaFacebookSquare as Facebook,
  FaTwitter as Twitter,
  FaInstagram as Instagram,
  FaSpotify as Spotify,
  FaApple as AppleMusic,
  FaYoutube as YouTube,
} from "react-icons/fa"

const Footer = () => {
  const siteMeta = UseSiteMetadata()
  return (
    <FooterStyles style={{ marginBottom: 0 }} className="section">
      <div className="container container__tight">
        <small>All content copyright Capsized</small>
        {siteMeta.spotifyUrl ||
        siteMeta.appleMusicUrl ||
        siteMeta.youTubeUrl ||
        siteMeta.twitterUsername ||
        siteMeta.facebookUsername ||
        siteMeta.instagramUsername ? (
          <FooterMenuStyles className="footer__menu social__menu">
            <h2 className={'visually-hidden'}>
              Follow us on the bullshits<span>.</span>
            </h2>
            <ul>
              {siteMeta.spotifyUrl && (
                <li>
                  <a
                    href={`${siteMeta.spotifyUrl}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <Spotify />
                  </a>
                </li>
              )}
              {siteMeta.appleMusicUrl && (
                <li>
                  <a
                    href={`${siteMeta.appleMusicUrl}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <AppleMusic />
                  </a>
                </li>
              )}
              {siteMeta.youTubeUrl && (
                <li>
                  <a
                    href={`${siteMeta.youTubeUrl}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <YouTube />
                  </a>
                </li>
              )}
              {siteMeta.twitterUsername && (
                <li>
                  <a
                    href={`https://www.twitter.com/${siteMeta.twitterUsername}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <Twitter />
                  </a>
                </li>
              )}
              {siteMeta.facebookUsername && (
                <li>
                  <a
                    href={`https://www.facebook.com/${siteMeta.facebookUsername}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <Facebook />
                  </a>
                </li>
              )}
              {siteMeta.instagramUsername && (
                <li>
                  <a
                    href={`https://www.instagram.com/${siteMeta.instagramUsername}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    <Instagram />
                  </a>
                </li>
              )}
            </ul>
          </FooterMenuStyles>
        ) : (
          ""
        )}
      </div>
    </FooterStyles>
  )
}

export default Footer
