import * as React from "react"
import { BannerModuleStyles } from "./BannerModuleStyles"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"

const BannerModule = ({ children, small }) => {
  const isServer = typeof window === "undefined"

  const parallaxValue = () => {
    if (!isServer) {
      if (window.innerWidth < 768) {
        // return [-40,20]
        return [0, 0]
      } else if (window.innerWidth < 1024) {
        return [-40, 20]
      } else {
        return [-60, 45]
      }
    } else {
      return [-60, 45]
    }
  }

  let theHeaderImage

  if (!small) {
    theHeaderImage = (
      <Parallax translateY={parallaxValue()} className={"banner__image"}>
        <StaticImage
          className="banner__image"
          imgClassName="banner__image--content"
          src="../../../static/hero-image-2.jpg"
          alt="Capsized band photo"
          layout="Constrained"
          placeholder="blurred"
          transformOptions={{ grayscale: false, fit: "contain" }}
          objectFit={"cover"}
          objectPosition="50% 0"
        />
      </Parallax>
    )
  }

  const theBannerContent = (
    <>
      {theHeaderImage}
      <div className="container">
        <Link to={"/"}>
          <StaticImage
            className="logo__image"
            imgClassName="banner__image--content"
            src="../../../static/cancersized-logo 2.png"
            alt="Capsized logo"
            layout={"constrained"}
            transformOptions={{ grayscale: false, fit: "contain" }}
            placeholder="blurred"
          />
        </Link>
      </div>
    </>
  )


  return (
    <ParallaxProvider>
      <BannerModuleStyles className={small ? "small" : null}>
        {children ? (
          children
        ) : (
          theBannerContent
        )}
      </BannerModuleStyles>

      <span id="topContent" />
    </ParallaxProvider>
    // </div>
  )
}

export default BannerModule
