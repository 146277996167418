import styled from "styled-components"

export const BannerModuleStyles = styled.section`
  position: relative;
  //background: #F4F2EE;
  padding: 30px var(--borderSpacing);
  overflow: hidden;
  height: 50vh;

  @media (min-width: 768px) {
    height: 60vh;
  }

  @media (min-width: 1024px) {
    height: 80vh;
  }

  &.small {
    //display: none;
    height: 25vh;
    overflow: visible;

    @media (min-width: 768px) {
      height: 30vh;
    }

    @media (min-width: 1024px) {
      height: 40vh;
    }

    .logo__image {
      margin: 3vh auto 0;
      
      @media (min-width: 768px) {
        margin: 5vh auto 0;
        width: 80vw;
      }

      @media (min-width: 1200px) {
        width: 50vw;
      }
    }
  }

  .container {
    height: 100%;
    max-width: 1200px;
  }

  .banner__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  .logo__image {
    position: relative;
    display: block;
    margin: 15vh auto 0;
    width: 100%;

    @media (min-width: 768px) {
      margin: 25vh auto 0;
      width: 80vw;
    }

    @media (min-width: 1200px) {
      margin: 33vh auto 0;
      width: 80%;
    }
    //height: 100%;
    z-index: 1;
  }

  .banner__content {
    position: relative;
    z-index: 2;
    min-height: 33vh;
    width: 100%;
    max-width: 700px;
  }
`
